import { Component, OnInit, ViewContainerRef } from '@angular/core';

import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { AzureInsightsService } from '@bbraun/helpex/src/lib/azure-insights';

import { environment } from '../environments/environment';
import { OAuthService } from './modules/shared/service/oAuth/oAuth.service';
import { TranslationService } from './modules/shared/service/translation/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  standalone: false
})
export class AppComponent implements OnInit {

  constructor(
    private readonly translationService: TranslationService,
    private readonly oAuthService: OAuthService,
    private readonly snackbarService: CxSnackbarService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly azureInsightsService: AzureInsightsService
  ) {
    this.snackbarService.setViewContainerRef(viewContainerRef);
    this.azureInsightsService.handleMonitoring(environment.monitoring.enable, environment.monitoring.azureInsightsKey);
  }
  ngOnInit(): void {
    this.translationService.init();
    this.oAuthService.configureAuth();
  }
}
